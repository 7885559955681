<template>
  <div class="conversion_conten">
    <new-header :config="headerConfig"></new-header>
    <div class="content_top">
      <div class="content_box">
        <img :src="tableData.user.user_avatar" />
        <div>
          <div class="title_c">{{ tableData.user.user_name }}</div>
          <div style="display: flex">
            <div class="vipImg">
              <img
                style="width: 45px; height: 23px; margin: 7px 5px 0 0"
                src="../../assets/lador_leve.png"
              />
              <div class="vip_text">V{{ tableData.user.member.vip_level }}</div>
            </div>
            <img
              class="lador_icon"
              v-if="tableData.user.union_member != 0"
              src="../../assets/ladoricon.png"
              alt=""
            />
            <img
              class="lador_icon"
              v-if="tableData.user.union_shareholder != 0"
              src="../../assets/gufen.png"
              alt=""
            />
          </div>
          <div class="text_c">{{ tableData.user.union_expire_at.split(" ")[0] }}到期</div>
        </div>
        <div class="price_c">
          <div>{{ tableData.user.member.available_assets }}</div>
          <div class="btn">补金</div>
        </div>
      </div>
    </div>
    <div class="conversion_btn_i" @click="conversionBtn">
      <img class="conversion_icon" src="../../assets/Slice47.png" />工分增值
    </div>
    <div class="conversion_list_i">
      <div class="conversion_item" v-for="(item, index) in dataList" :key="index">
        <div>
          <div style="color: #010101">{{ item.create_time }}</div>
          <div style="color: #707070; margin: 15px 0">
            兑换方式:{{
              item.apply_type == 1
                ? "分享式"
                : item.apply_type == 2
                ? "置换式"
                : "增值式"
            }}（1:{{ item.shares_cardinal }}）
          </div>
          <div style="color: #707070">兑换工分:{{ item.shares_amount }}</div>
        </div>
        <div class="status">
          <div style="color: rgba(227, 180, 103, 1)" v-if="item.audit_state == 1">
            待处理
          </div>
          <div style="color: rgba(51, 46, 45, 1)" v-if="item.audit_state == 2">
            已通过
          </div>
          <div style="color: rgba(227, 180, 103, 1)" v-if="item.audit_state == 0">
            进行中
          </div>
          <div style="color: rgba(245, 67, 52, 1)" v-if="item.audit_state == 3">
            已失败
          </div>
          <div class="details" @click="details(item.apply_id)">查看详情</div>
        </div>
      </div>
      <div class="clonelist" v-if="dataList.length == 0">
        <img src="../../assets/list.png" />
        <div style="margin-top: 10px">暂无兑换记录</div>
      </div>
    </div>
  </div>
</template>
<script>
import { dealDetails_api, conversionList_api } from "@/api/deal";
import newHeader from "@/components/newHeader";
export default {
  data() {
    return {
      tableData: {
        user: {
          member: {},
        },
        union_shareholder_expire_at: "",
      },
      headerConfig: {
        show: true,
        title: "工分增值",
      },
      dataList: [],
    };
  },
  components: {
        newHeader
    },
  async created() {
    await this.getHome();
    this.getList();
  },
  methods: {
    getHome() {
      dealDetails_api().then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          this.$forceUpdate();
        }
      });
    },
    getList() {
      conversionList_api(this.queryList).then((res) => {
        if (res.code == 0) {
          console.log(1);
          this.dataList = res.data;
        }
      });
    },
    conversionBtn() {
      this.$router.push("./present");
    },
    details(id) {
      this.$router.push(`./orderDetails?id=${id}`);
    },
  },
};
</script>
<style scoped>
.clonelist {
  margin: 278px auto 0;
  width: 165px;
  height: 170px;
  font-size: 25px;
  color: #c5c5c5;
  text-align: center;
}
.conversion_conten {
  background-color: rgba(242, 242, 242, 1);
  height: 100%;
}
.clonelist img {
  width: 100%;
  height: 100%;
}
.content_top {
  width: 750px;
  height: 317px;
  background: #2e2e30;
  opacity: 1;
  border-radius: 0 0 100px 100px;
  position: relative;
}
.content_box {
  width: 726px;
  height: 300px;
  display: flex;
  background-image: linear-gradient(120deg, #f0d099, #dab274);
  position: absolute;
  top: 70px;
  left: 12px;
  border-radius: 30px;
  padding: 20px;
  box-sizing: border-box;
}
.content_box > img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 20px;
}
.list_img img {
  width: 95px;
  height: 95px;
  /* margin: 18px 21px; */
}
.list_icon {
  margin-right: 75px;
  text-align: center;
  margin-top: 20px;
}
.vipImg {
  position: relative;
}
.vip_text {
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 18px;
  color: #d4b479;
}
.lador_icon {
  width: 46px;
  height: 46px;
  margin-top: 15px;
  margin-right: 15px;
}
.title_c {
  font-size: 35px;
  color: #563b15;
}
.text_c {
  font-size: 25px;
  color: #563b15;
}
.price_c {
  position: absolute;
  bottom: 50px;
  right: 65px;
  text-align: center;
}
.price_c {
  font-size: 60px;
  color: #fff;
}
.price_c .btn {
  width: 120px;
  height: 45px;
  background: #fef8ec;
  opacity: 1;
  border-radius: 5px;
  font-size: 25px;
  color: #563b15;
  text-align: center;
  line-height: 45px;
  margin-top: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.conversion_btn_i {
  margin-top: 100px;
  width: 200px;
  height: 59px;
  line-height: 59px;
  background: linear-gradient(180deg, #eed09d 0%, #e4c085 100%);
  box-shadow: 0px 0px 15px rgba(223, 183, 136, 0.35);
  opacity: 1;
  border-radius: 10px;
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin-left: 12px;
}
.conversion_list_i {
  width: 750px;
  margin: 0 auto;
}
.conversion_item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 720px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(120, 120, 120, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #deb77a;
  font-size: 25px;
  padding: 37px;
  box-sizing: border-box;
}
.conversion_item div {
  margin-top: 10px;
}
.status {
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}
.conversion_item .details {
  margin-top: 47px;
  width: 200px;
  height: 80px;

  box-shadow: 0px 4px 10px 0px rgba(184, 152, 100, 0.5);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 1px solid #d6b06f;

  background: #2e2e30;
  line-height: 80px;
  text-align: center;
  color: #fff;
}
.conversion_icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
</style>
